@use "../util/" as *;

// .layout-one {
.location {
  z-index: 2;
  .country {
    font-size: $heading-large;
    font-weight: 500;
    text-shadow: 0 4px 4px rgb(0, 0, 0, 0.25);
    line-height: 1;
    display: flex;
    flex-direction: column;
    &.smaller-font-size {
      font-size: $heading-medium;

      text-shadow: 4px 4px 4px rgb(0, 0, 0, 0.5);
    }
  }
  .place {
    font-size: $heading-small;
    text-shadow: 0 0 3px rgb(0, 0, 0, 0.5);
    &.smaller-font-size {
      font-size: $heading-xsmall;
    }
  }
}
// }

.layout-two {
  .location {
    text-shadow: 2px 2px 4px rgb(0, 0, 0, 0.45);
  }
}

@media (max-width: $bp-desktop) {
  .layout-two {
    .location {
      .country {
        text-align: center;
      }
      .place {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media (max-width: $bp-phone) {
  .layout-one,
  .layout-two {
    .location {
      .country {
        font-size: $heading-small;
      }
      .place {
        font-size: $heading-xsmall;
      }
    }
  }
}
