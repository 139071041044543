@use "../util/" as *;

.forecast-day {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-color: var(--clr-primary-fade03);
  backdrop-filter: blur(2px);
  padding: 2rem 2.25rem;
  border-radius: $radius-medium;
  text-align: center;
  width: 8.5rem;
  h3 {
    text-transform: uppercase;
    font-weight: 300;
    font-size: $text-medium-large;
  }
  img {
    width: 3.25rem;
    max-width: 3.25rem;
  }
  .temperature {
    font-size: $text-medium;
    font-weight: 400;
    font-size: $text-medium-large;
  }
}

.layout-two {
  .forecast-day {
    flex-direction: row;
    max-height: max-content;
    padding: 1rem 2rem;
    width: 100%;
    & > * {
      flex: 1;
    }
  }
}

@media (max-width: $bp-phone) {
  .layout-one {
    .forecast-day {
      padding: 1.25rem 0;
      flex: 1;
    }
  }
}
