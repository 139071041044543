@use "../util/" as *;

.background-image {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  user-select: none;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &.layout-two-styles {
      filter: blur(50px);
      transform: scale(1.25);
    }
  }
}

@media (max-width: $bp-desktop) {
  .background-image {
    img.layout-two-styles {
      filter: blur(0);
      transform: scale(1);
    }
  }
}

@media (max-width: $bp-phone) {
  .background-image {
    height: 120vh;
  }
}
