@use "../util" as *;

.day-night-weather {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: $heading-small;
  gap: 1rem;
  font-weight: 300;
  z-index: 2;
  text-shadow: 2px 2px 2px rgb(0, 0, 0, 0.45);
  line-height: 1;
}

.layout-one,
.layout-two {
  .day-night-weather {
    .seperator {
      height: 4rem;
    }
  }
}

.layout-two {
  .day-night-weather {
    justify-content: flex-start;
    font-size: $heading-xsmall;
    .seperator {
      height: 1em;
    }
  }
}

@media (max-width: $bp-desktop) {
  .layout-one .day-night-weather {
    justify-content: flex-start;
  }
}

@media (max-width: $bp-phone) {
  .layout-one,
  .layout-two {
    .day-night-weather {
      font-size: $text-medium-large;
      margin: 1rem 0;
      text-shadow: 2px 2px 2px rgb(0, 0, 0, 0.45);
    }
  }
  .layout-two {
    .day-night-weather {
      padding: 0 1.5rem;
    }
  }
}
