@use "../util" as *;

.search {
  position: relative;
  z-index: 3;
  label {
    display: flex;
    align-items: center;
    .search-btn {
      position: absolute;
      left: 0.75rem;
      cursor: default;
      &.clickable {
        cursor: pointer;
      }
      img {
        width: 1.75rem;
      }
    }
  }
  input {
    padding: 0.5rem 3.25rem;
    padding-right: 3rem;
    font-size: $text-medium;
    border-radius: $radius-medium;
    width: 100%;
    outline: none;
    border: 2px solid transparent;
    transition: border 0.3s ease;
    caret-color: var(--clr-accent);
    &::placeholder {
      color: var(--clr-accent-fade03);
    }
    &:focus,
    &:active {
      border: 2px solid var(--clr-accent-fade07);
    }
  }
  .clear-btn {
    position: absolute;
    right: 0.25rem;
    padding: 0.75rem;
    img {
      width: 0.75rem;
    }
  }
}

@media (max-width: $bp-phone) {
  .layout-one,
  .layout-two {
    .search {
      input {
        font-size: $text-small;
      }
    }
  }
}
