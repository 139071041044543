@use "../util" as *;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  color: var(--clr-secondary);
}

a,
a:visited,
a:active {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1;
}

button {
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
}

#root {
  @include flexCenter(column);
  width: 100vw;
  min-height: 100vh;
}

// accessibility for icons
.visually-hidden {
  clip: rect(0 0 0 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

#loaderOneDesktop,
#loaderOneMobile,
#loaderTwoDesktop,
#loaderTwoMobile {
  animation: fadeInUp forwards 0.3s ease;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  max-height: 100%;
}

#loaderOneMobile,
#loaderTwoMobile {
  display: none;
}

@media (max-width: $bp-desktop) {
  #loaderOneDesktop,
  #loaderTwoDesktop {
    display: none;
  }

  #loaderOneMobile,
  #loaderTwoMobile {
    display: block;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(5%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
